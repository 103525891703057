import { IconProps } from './types';

export default function ChevronLeft({ height, width, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 24 51.46"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M693.16 409.43a2 2 0 0 1-1.48-.66l-19.25-21.65a5.16 5.16 0 0 1 0-6.84l19.25-21.64a2 2 0 1 1 3 2.63l-19.24 21.64a1.19 1.19 0 0 0 0 1.58l19.24 21.65a2 2 0 0 1-1.47 3.29Z"
                fill="currentColor"
                transform="translate(-671.13 -357.97)"
            />
        </svg>
    );
}
